import React, { Component } from 'react'
import {toast} from "react-toastify";

class Whitepaper extends Component {

    render() {

        return (
            <div className="row rowReferral">
                <div className="col-12 text-center">
                    <h1 id="whitepaperTitle">Whitepaper</h1>
                </div>
            </div>
        );
    }
}

export default Whitepaper;
