import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class TheGameNftCardBox extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let stats = [];

        switch (this.props.nftType) {
            case 'ecryptogirl':
                stats = [
                    {
                        'name': 'Defense',
                        'icon': 'heart',
                        'value': '77',
                    },
                    {
                        'name': 'Attack',
                        'icon': 'heart',
                        'value': '97',
                    },
                    {
                        'name': 'Speed',
                        'icon': 'heart',
                        'value': '53',
                    },
                    {
                        'name': 'Fly',
                        'icon': 'heart',
                        'value': '77',
                    }
                ];
                break;
            case 'district':
                stats = [
                    {
                        'name': 'Gangsters',
                        'icon': 'heart',
                        'value': 'Dangerous',
                    },
                    {
                        'name': 'Citizens',
                        'icon': 'heart',
                        'value': '20k',
                    },
                    {
                        'name': 'Difficulty',
                        'icon': 'heart',
                        'value': '3',
                    }
                ];
                break;
        }


        let statsHtml = [];
        for (const [index, value] of stats.entries()) {

            let statSingleHtml =
                <div className={"col-" + (12 / stats.length) + " nftCardBoxStatsSingleContainer"}>
                    <div className="row nftCardBoxStatsSingleContainerRow">
                        <div className="col-12 nftCardBoxStatsName">
                            {value.name}
                        </div>
                        <div className="col-6 nftCardBoxStatsImageContainer">
                            <img style={{height: '20px'}} src={require('../nfts/stats-icon/' + value.icon + '.svg')} alt=""/>
                        </div>
                        <div className="col-6 nftCardBoxStatsValue">
                            {value.value}
                        </div>
                    </div>
                </div>

            statsHtml.push(statSingleHtml);
        }

        let urlNftSingle = '/the-game/nft/' + this.props.tokenId;

        let classNftCardBox = 'col-md-4 nftCardBoxGrid';
        if (this.props.viewMode == 'list') {
            classNftCardBox = 'col-md-12 nftCardBoxList';
        }

        //console.log('this.props.viewMode');
        //console.log(this.props.viewMode);

        return (
            <Link key={'nftCard' + this.props.tokenId} to={urlNftSingle} className={"col-12 " + classNftCardBox + " nftCardBox"}>
                <div className="row">
                    <div className="col-12 nftCardBoxNameNFT">
                        Name of NFT
                    </div>
                    <div className="col-12 nftCardBoxTokenId">
                        #{this.props.tokenId}
                    </div>
                    <div className="col-12 nftCardBoxLevel">
                        Level 7
                    </div>
                    <div className="col-12 nftCardBoxNameImageContainer text-center">
                        <img style={{height: '150px'}} src={require('../nfts/nft-card-' + this.props.nftType + '.png')} alt=""/>
                    </div>
                    <div className="col-12 nftCardBoxStatsContainer">
                        <div className="row nftCardBoxStatsContainerRow">

                            {statsHtml}

                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default withRouter(TheGameNftCardBox);
