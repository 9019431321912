import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import TheGamePlay from "./TheGamePlay";
import TheGameGetCards from "./TheGameGetCards";
import TheGameNftMarketplace from "./TheGameNftMarketplace";
import TheGameMyNfts from "./TheGameMyNfts";

class TheGame extends Component {

    constructor(props) {
        super(props);

        let currentTab = '';

        switch (this.props.match.params.tab) {
            case 'play':
            case 'get-new-cards':
            case 'nft-marketplace':
            case 'my-nfts':
                currentTab = this.props.match.params.tab;
                break;
            default:
                currentTab = 'play';
        }

        let param1 = this.props.match.params.param1;
        let param2 = this.props.match.params.param2;
        let param3 = this.props.match.params.param3;

        this.state = {
            currentTab: currentTab,
            param1: param1,
            param2: param2,
            param3: param3,
        }
    }

    changeParam3(newValue) {
        this.state.param3 = newValue;
        console.log(this.state.param3);
    }

    changeGameTab(newCurrentTab) {
        this.setState({
            currentTab: newCurrentTab
        })
        window.history.pushState(newCurrentTab, newCurrentTab, '/the-game/' + newCurrentTab);
    }

    render() {

        let gameContainer = '';
        let gameMenuPlayClass = '';
        let gameMenuGetNewCardsClass = '';
        let gameMenuNFTMarketPlaceClass = '';
        let gameMenuMyNFTsClass = '';

        switch (this.state.currentTab) {
            /*
            case 'play':
                gameContainer = <TheGamePlay />;
                gameMenuPlayClass = 'active';
                break;
            */
            case 'get-new-cards':
                gameContainer = <TheGameGetCards />;
                gameMenuGetNewCardsClass = 'active';
                break;
            case 'nft-marketplace':
                gameContainer = <TheGameNftMarketplace
                    param1={this.state.param1}
                    param2={this.state.param2}
                    param3={this.state.param3}

                    changeParam3={this.changeParam3.bind(this)}

                />;
                gameMenuNFTMarketPlaceClass = 'active';
                break;
            case 'my-nfts':
                gameContainer = <TheGameMyNfts />;
                gameMenuMyNFTsClass = 'active';
                break;
            default:
                gameContainer = <TheGamePlay />;
                gameMenuPlayClass = 'active';
        }

        return (
            <div className="row rowReferral">
                <div className="col-12 text-center theGameTitleContainer">
                    <h1 id="theGameTitle">The Game</h1>
                </div>
                <div className="col-12 text-center theGameMenu">

                    <Link className={gameMenuPlayClass} to="/the-game/play" onClick={(event => {
                        event.preventDefault();
                        this.changeGameTab('play');
                    })}>Play</Link>

                    <Link className={gameMenuGetNewCardsClass} to="/the-game/get-new-cards" onClick={(event => {
                        event.preventDefault();
                        this.changeGameTab('get-new-cards');
                    })}>Get Cards</Link>

                    <Link className={gameMenuNFTMarketPlaceClass} to="/the-game/nft-marketplace" onClick={(event => {
                        event.preventDefault();
                        this.changeGameTab('nft-marketplace');
                    })}>NFT Marketplace</Link>

                    <Link className={gameMenuMyNFTsClass} to="/the-game/my-nfts" onClick={(event => {
                        event.preventDefault();
                        this.changeGameTab('my-nfts');
                    })}>My NFT's</Link>

                </div>
                <div className="col-12 gameContainerGlobal">
                    {gameContainer}
                </div>
            </div>
        );
    }
}

export default withRouter(TheGame);
