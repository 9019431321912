import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import TheGameNftCardBox from "./TheGameNftCardBox";
import IconList from "../img/list-icon.svg"
import IconGrid from "../img/grid-icon.svg"

class TheGameNftMarketplace extends Component {

    constructor(props) {
        super(props);

        let nftTypeOptions = [
            {
                'slug': 'heroes',
                'text': 'Heroes',
            },
            {
                'slug': 'districts',
                'text': 'Districts',
            },
        ];

        let orderOptions = [
            {
                'slug': 'lowest-price',
                'text': 'Lowest Price',
            },
            {
                'slug': 'highest-price',
                'text': 'Highest Price',
            },
            {
                'slug': 'lowest-id',
                'text': 'Lowest ID',
            },
            {
                'slug': 'highest-id',
                'text': 'Highest ID',
            },
            {
                'slug': 'latest',
                'text': 'Latest',
            },
        ];


        let param1 = this.props.param1;
        let param2 = this.props.param2;
        let param3 = this.props.param3;


        let currentNftTypeOption = '';
        let currentNftTypeOptionText = '';

        for (var i = 0; i < nftTypeOptions.length; i++) {
            let nftTypeOption = nftTypeOptions[i];

            if (param1 == nftTypeOption.slug) {
                currentNftTypeOption = nftTypeOption.slug;
                currentNftTypeOptionText = nftTypeOption.text;
            }
        }

        if (currentNftTypeOption == '') {
            currentNftTypeOption = 'heroes';
            currentNftTypeOptionText = 'Heroes';
        }


        let currentOrderOption = '';
        let currentOrderOptionText = '';

        for (var i = 0; i < orderOptions.length; i++) {
            let orderOption = orderOptions[i];

            if (param2 == orderOption.slug) {
                currentOrderOption = orderOption.slug;
                currentOrderOptionText = orderOption.text;
            }
        }

        if (currentOrderOption == '') {
            currentOrderOption = 'lowest-price';
            currentOrderOptionText = 'Lowest Price';
        }

        let viewModes = [
            'list',
            'grid',
        ];

        let currentViewMode = '';
        if (viewModes.includes(param3)) {
            currentViewMode = param3;
        } else {
            currentViewMode = 'grid';
        }

        this.state = {
            cardLevel: 13,

            nftTypeOptions: nftTypeOptions,
            orderOptions: orderOptions,

            currentOrderOption: currentOrderOption,
            currentOrderOptionText: currentOrderOptionText,

            currentNftTypeOption: currentNftTypeOption,
            currentNftTypeOptionText: currentNftTypeOptionText,

            currentViewMode: currentViewMode,
        }
    }

    render() {

        let htmlNftTypeOptions = new Array();
        for (var i = 0; i < this.state.nftTypeOptions.length; i++) {
            let nftTypeOption = this.state.nftTypeOptions[i];

            let classNftType = '';
            if (this.state.currentNftTypeOption == nftTypeOption.slug){
                classNftType = 'active';
            }

            htmlNftTypeOptions.push(
                <Link onClick={(event => {
                    this.state.currentNftTypeOption = nftTypeOption.slug;
                    this.state.currentNftTypeOptionText = nftTypeOption.text;
                })} to={'/the-game/nft-marketplace/' + nftTypeOption.slug + '/' + this.state.currentOrderOption + '/' + this.state.currentViewMode} className={"gameContainerMarketPlaceNFTTypeSingle " + classNftType}>{nftTypeOption.text}</Link>
            );
        }

        let htmlOrderOption = new Array();
        for (var i = 0; i < this.state.orderOptions.length; i++) {
            let orderOption = this.state.orderOptions[i];

            let classOrderOption = 'col-12 orderOptionLink';
            if (this.state.currentOrderOption == orderOption.slug){
                classOrderOption += ' active';
            }

            htmlOrderOption.push(
                /*<Dropdown.Item className={classOrderOption} href={"/the-game/nft-marketplace/" + this.state.currentNftTypeOption + '/' + orderOption.slug + '/' + this.state.currentViewMode}>{orderOption.text}</Dropdown.Item>*/
                <Link className={classOrderOption} href={"/the-game/nft-marketplace/" + this.state.currentNftTypeOption + '/' + orderOption.slug + '/' + this.state.currentViewMode}>{orderOption.text}</Link>
            );
        }


        let htmlNFTsResults = new Array();
        for (var i = 0; i < 1; i++) {
            htmlNFTsResults.push(
                <TheGameNftCardBox
                    key={'TokenId' + (i+70)}
                    tokenId={i+70}
                    nftType="ecryptogirl"
                    viewMode={this.state.currentViewMode}
                />
            );
        }

        let classListIcon = '';
        let classGridIcon = '';

        if (this.state.currentViewMode == 'list') {
            classListIcon = 'active';
        } else {
            classGridIcon = 'active';
        }

        return (
            <div className="row rowMarketPlaceNFT">
                <div className="col-12 gameContainerMarketPlaceNFT">

                    <div className="row gameContainerMarketPlaceNFTTitleRow">
                        <div className="col-12 gameContainerMarketPlaceNFTTitleCol">
                            <h1>NFT Marketplace!</h1>
                        </div>
                    </div>

                    <div className="row gameContainerMarketPlaceNFTFilterRow">

                        <div className="col-12 col-md-6 gameContainerMarketPlaceNFTFiltersCol">
                            {htmlNftTypeOptions}
                        </div>

                        <div className="col-6 col-md-3 gameContainerMarketPlaceNFTOrderCol">
                            <Dropdown>
                                <Dropdown.Toggle id="nftMarketplaceOrder">
                                    {this.state.currentOrderOptionText}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <div className="row rowOrderItemsMenu">
                                        {htmlOrderOption}
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="col-6 col-md-3 gameContainerMarketPlaceNFTViewModeCol text-right">
                            <Link className={classListIcon} onClick={(event => {
                                this.state.currentViewMode = 'list';
                                this.props.changeParam3('list');
                            })} to={"/the-game/nft-marketplace/" + this.state.currentNftTypeOption + '/' + this.state.currentOrderOption + '/list'}>
                                <img style={{height: '25px'}} src={IconList} alt=""/>
                            </Link>
                            <Link className={classGridIcon} onClick={(event => {
                                this.state.currentViewMode = 'grid';
                                this.props.changeParam3('grid');
                            })} to={"/the-game/nft-marketplace/" + this.state.currentNftTypeOption + '/' + this.state.currentOrderOption + '/grid'}>
                                <img style={{height: '25px'}} src={IconGrid} alt=""/>
                            </Link>
                        </div>

                    </div>

                    <div className="row gameContainerMarketPlaceNFTResultContainerRow">
                        {htmlNFTsResults}
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(TheGameNftMarketplace);
