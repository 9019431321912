import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class TheGameNftSingle extends Component {

    constructor(props) {
        super(props);

        let tokenId = this.props.match.params.tokenId;

        this.state = {
            tokenId: tokenId,
        }
    }

    render() {

        let nftType = 'ecryptogirl';

        let stats = [];

        switch (nftType) {
            case 'ecryptogirl':
                stats = [
                    {
                        'name': 'Defense',
                        'icon': 'heart',
                        'value': '77',
                    },
                    {
                        'name': 'Attack',
                        'icon': 'heart',
                        'value': '97',
                    },
                    {
                        'name': 'Speed',
                        'icon': 'heart',
                        'value': '53',
                    },
                    {
                        'name': 'Fly',
                        'icon': 'heart',
                        'value': '77',
                    }
                ];
                break;
            case 'district':
                stats = [
                    {
                        'name': 'Gangsters',
                        'icon': 'heart',
                        'value': 'Dangerous',
                    },
                    {
                        'name': 'Citizens',
                        'icon': 'heart',
                        'value': '20k',
                    },
                    {
                        'name': 'Difficulty',
                        'icon': 'heart',
                        'value': '3',
                    }
                ];
                break;
        }


        let statsHtml = [];
        for (const [index, value] of stats.entries()) {

            let statSingleHtml =
                <div className={"col-" + (12 / stats.length) + " nftCardBoxStatsSingleContainer"}>
                    <div className="row nftCardBoxStatsSingleContainerRow">
                        <div className="col-12 nftCardBoxStatsName">
                            {value.name}
                        </div>
                        <div className="col-6 nftCardBoxStatsImageContainer">
                            <img style={{height: '20px'}} src={require('../nfts/stats-icon/' + value.icon + '.svg')} alt=""/>
                        </div>
                        <div className="col-6 nftCardBoxStatsValue">
                            {value.value}
                        </div>
                    </div>
                </div>

            statsHtml.push(statSingleHtml);
        }

        return (
            <div className="row rowNftSingleGlobalContainer">

                <div className="col-12 col-md-6 nftSingleFirstColumn">
                    <div className="row">
                        <div className="col-12 backLinkContainer">
                            <Link to="">Back</Link>
                        </div>
                        <div className="col-12 gameContainerNftSingleTokenId">
                            #{this.state.tokenId}
                        </div>
                        <div className="col-12 gameContainerNftSingleTitle">
                            <h1>NFT Single!</h1>
                        </div>
                        <div className="col-12 gameContainerNftSingleImage text-center">
                            <img style={{height: '150px'}} src={require('../nfts/nft-card-' + nftType + '.png')} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 nftSingleSecondColumn">
                    <div className="row">
                        <div className="col-12 gameContainerNftSingleAbout">
                            <h2>About</h2>
                        </div>

                        <div className="col-12 gameContainerNftSingleAboutData">
                            <div className="row">

                                <div className="col-6 gameContainerNftSingleAboutContainerSingle">
                                    <div className="row">
                                        <div className="col-12 gameContainerNftSingleAboutContainerSingleTitle">
                                            Type
                                        </div>
                                        <div className="col-12 gameContainerNftSingleAboutContainerSingleValue">
                                            Hero
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 gameContainerNftSingleAboutContainerSingle">
                                    <div className="row">
                                        <div className="col-12 gameContainerNftSingleAboutContainerSingleTitle">
                                            Level
                                        </div>
                                        <div className="col-12 gameContainerNftSingleAboutContainerSingleValue">
                                            7
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 gameContainerNftSingleOwnerContainer">
                                    <div className="row">
                                        <div className="col-12 gameContainerNftSingleOwnerContainerTitle">
                                            Owner
                                        </div>
                                        <div className="col-12 gameContainerNftSingleOwnerContainerTitleValue">
                                            0xc0ffee254729296a45a3885639AC7E10F9d54979
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-12 gameContainerNftSingleStats">
                            <h2>Stats</h2>
                        </div>

                        <div className="col-12 gameContainerNftSingleStatsData">
                            <div className="row">
                                {statsHtml}
                            </div>
                        </div>

                        <div className="col-12 gameContainerNftSingleActions">
                            <h2>Actions</h2>
                        </div>

                        <div className="col-12 gameContainerNftSingleActionsButtons">
                            <a className="btn btn-primary" href="">Upgrade</a>
                            <a className="btn btn-primary" href="">Sell</a>
                        </div>

                    </div>
                </div>




            </div>
        );
    }
}

export default withRouter(TheGameNftSingle);
