import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";
import TheGameNftCardBox from "./TheGameNftCardBox";

class TheGameMyNfts extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let htmlCryptogirlsNFTs = new Array();
        for (var i = 0; i < 4; i++) {
            htmlCryptogirlsNFTs.push(
                <TheGameNftCardBox
                    tokenId={i+70}
                    nftType="ecryptogirl"
                />
            );
        }

        let htmlDistrictsNFTs = new Array();
        for (var i = 0; i < 2; i++) {
            htmlDistrictsNFTs.push(
                <TheGameNftCardBox
                    tokenId={i+100}
                    nftType="district"
                />
            );
        }

        return (
            <div className="row rowReferral">
                <div className="col-12 gameContainerGetCards">
                    <h2>My NFT's!</h2>
                    <p>
                        Jos: basado en https://marketplace.axieinfinity.com/axie/7050457/
                    </p>

                    <div className="row nftTypeTitleRow">
                        <div className="col-12 nftTypeTitleCol">
                            Cryptogirls's
                        </div>
                    </div>

                    <div className="row">

                        {htmlCryptogirlsNFTs}

                    </div>

                    <div className="row nftTypeTitleRow">
                        <div className="col-12 nftTypeTitleCol">
                            District's
                        </div>
                    </div>

                    <div className="row">

                        {htmlDistrictsNFTs}

                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(TheGameMyNfts);
