import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import BigNumber from "bignumber.js";
import {BrowserRouter as Router, Link} from "react-router-dom";

class PoolBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            //closed: false,
            activeIndex: 'deposit',
            amountInput: 0,
            inputMax: 0,
        }
    }

    selectDeposit = () => {
        this.setState({
            activeIndex: 'deposit',
            amountInput: 0,
        })
    }

    selectWithdraw = () => {
        this.setState({
            activeIndex: 'withdraw',
            amountInput: 0,
        })
    }

    render() {

        let index = this.props.index;
        let value = this.props.value;
        let poolValue = this.props.value;
        let pid = value.pid;

        let classAddOperationContainerPoolBox = '';

        if (this.state.closed) {
            classAddOperationContainerPoolBox = ' closed';
        }

        let totalAllocPoint = this.props.getTotalAllocPointByMasterchefNum(value.masterchef);

        let poolInfo = this.props.getPoolInfo(value.masterchef, pid);

        let poolName = value.token1Name;
        //let image2 = '';

        let type = 'token';
        let typeName = '';
        let aprOrApyTxt = 'APR';
        let poolTypeSlug = 'pool';
        if (value.token2Name) {
            poolName += '-' + value.token2Name;
            //image2 += require('../tokens/' + value.token2Name.toLowerCase() + '.png');
            type = 'lp';
            typeName = 'LP';
            //aprOrApyTxt = 'APY';
            poolTypeSlug = 'farm';
        }

        let apr = 0;

        let remainingBlocks = null;

        if (value.masterchef == 2) {
            poolInfo.depositFeeBP = 1000;

            remainingBlocks = poolInfo.bonusEndBlock - this.props.currentBlock;

            if (remainingBlocks < 0) {
                remainingBlocks = 0;
            }
        }

        let depositFeePercent = -1;
        if (poolInfo.depositFeeBP) {
            depositFeePercent = poolInfo.depositFeeBP / 100
        }

        let poolDepositFeePercent;
        if (depositFeePercent >= 0) {
            poolDepositFeePercent = depositFeePercent;
        } else {
            poolDepositFeePercent = '...';
        }

        let depositFeeText = this.props.getDepositFeeTextByMasterchefNum(value.masterchef);

        let remainingBlocksText = ''
        if (remainingBlocks !== null) {
            remainingBlocks = remainingBlocks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            remainingBlocksText = remainingBlocks + ' remaining blocks'
        }

        let poolNameTitle = poolName;

        if (value.masterchef == 2) {
            poolNameTitle = <span>SLION <small>earn WBNB</small></span>
        }


        let noFeeTag = '';
        if (depositFeePercent == 0) {
            noFeeTag = <span className="infoTag">
                            No fee
                        </span>;
        }


        let tokenPerBlock = this.props.getTokensPerBlockByMasterchefNum(value.masterchef);

        let multiplier = 1;

        let stakingTokenPrice = value.lpPrice;

        let poolStakingTokenPrice = 'Loading...';
        if (stakingTokenPrice) {
            poolStakingTokenPrice = parseFloat(stakingTokenPrice).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        let rewardTokenPrice = this.props.getRewardTokenPriceByMasterchefNum(value.masterchef);

        let totalStaked = value.tokensBalance;
        if (totalStaked < 0.01) {
            totalStaked = 0.0001
        }
        let tokenPerBlockPool = tokenPerBlock * poolInfo.allocPoint / totalAllocPoint;

        if (type === 'token') {

            apr = this.props.getPoolApy(
                stakingTokenPrice,
                rewardTokenPrice,
                totalStaked,
                tokenPerBlockPool,
            );
        } else {

            let poolWeight = poolInfo.allocPoint / totalAllocPoint;

            let poolLiquidityUsd = value.tokensBalanceInBusd;
            if (poolLiquidityUsd < 0.01) {
                poolLiquidityUsd = 0.001;
            }

            apr = this.props.getFarmApy(
                poolWeight,
                rewardTokenPrice,
                poolLiquidityUsd,
                new BigNumber(tokenPerBlock)
            );
        }

        let tokensPools = value.pid;

        //if (value.masterchef == 1) {
        if (value.masterchef != 0) {
            tokensPools = value.masterchef + '-' + value.pid;
        }

        let earnTokenName = this.props.getEarnTokenNameByMasterchefNum(value.masterchef);

        /*
        let activeBadge = <span className="badge bg-success">Active</span>;

        if (value.masterchef == 4 && this.props.currentBlock < 10601550) {
            activeBadge = <span className="badge bg-success">Starts 3 Sept.</span>;
        }

        if (value.allocPoint == 0) {
            activeBadge = <span className="badge bg-danger">Inactive</span>;
        }
        */

        let calculatorLinkElement = <a href="" onClick={(event => {
            event.preventDefault();
            this.props.setAprToModal(apr)
            this.props.handleOpenModal()
        })}>
            <img src={Calculator} alt=""/>
        </a>

        let poolApr = 0;
        if (apr) {
            poolApr = parseFloat(apr).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            poolApr = '...';
        }

        let apy = '...';
        if (poolApr != '...') {
            /*console.log('apr');
            console.log(apr);*/
            apy = (Math.pow(1 + (apr / 100) / 365, 365) - 1) * 100;
            apy = parseFloat(apy).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //apy = apy.toLocaleString('fullwide', { useGrouping: false }).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let mainButton = <button
            className="btn btn-primary unlockWalletButton"
            onClick={(event => {
                event.preventDefault();
                this.props.connectWalletConnect(true);
            })}
        >
            Connect Wallet
        </button>;

        let harvestButton =
            <button className="btn btn-secondary disabled harvestButton" disabled="disabled">
                Claim
            </button>;

        let compoundButton = '';


        if (poolValue.pid === 0 && poolValue.masterchef != 2) {
            compoundButton = <button className="btn btn-secondary disabled compoundButton" disabled="disabled">
                Compound
            </button>;
        }

        let canOperate = false;

        let dataUserPool = this.props.getDataUserPool(poolValue.masterchef, poolValue.pid);
        let getDataUserBalance = this.props.getDataUserBalance(poolValue.masterchef, poolValue.pid);


        let userBalanceUSD = (
            (
                parseFloat(
                    window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')
                    *
                    poolStakingTokenPrice
                )
            )

            +

            (
                parseFloat(
                    window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether')
                    *
                    rewardTokenPrice
                )

            )
        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (this.props.account !== '0x0') {

            if (dataUserPool.approvedQty != 0) {

                mainButton = ''
                canOperate = true;

                if (this.state.activeIndex == 'deposit') {

                    let userBalance = parseFloat(window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether'));

                    if (userBalance != this.state.inputMax) {
                        this.setState({
                            inputMax: userBalance
                        })
                    }

                } else {

                    let stakedAmountMax = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether');

                    if (stakedAmountMax != this.state.inputMax) {
                        this.setState({
                            inputMax: stakedAmountMax
                        })
                    }

                }

            } else {

                mainButton = <button
                    className="btn btn-primary approveButton"
                    onClick={(event => {
                        event.preventDefault();
                        this.props.approveContract(poolValue.masterchef, type, poolValue.lpToken);
                    })}
                >
                    Approve {poolName}
                </button>
            }

            if (dataUserPool.rewardDebt > 0) {
                harvestButton =
                    <button
                        className="btn btn-primary harvestButton"
                        onClick={(event => {
                            event.preventDefault()
                            let amount
                            amount = '0';

                            this.props.withdraw(poolValue.masterchef, poolValue.pid, amount)
                        })}
                    >
                        Claim
                    </button>;


                if (poolValue.pid === 0  && poolValue.masterchef != 2) {
                    compoundButton = <button
                        className="btn btn-primary compoundButton"
                        onClick={(event => {
                            event.preventDefault()
                            let amount
                            amount = window.web3.utils.fromWei(dataUserPool.rewardDebt, 'Ether');

                            this.props.deposit(poolValue.masterchef, poolValue.pid, amount);
                        })}
                    >
                        Compound
                    </button>
                }
            }
        }

        if (this.state.activeIndex === 'deposit') {
            let userBalance = window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether');
            if (this.state.amountInput > userBalance) {
                this.state.maxAmountInput = userBalance;
            }
        } else {
            let stakedAmount = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether');
            if (this.state.amountInput > stakedAmount) {
                this.state.maxAmountInput = stakedAmount;
            }
        }

        let depositClass = '';
        let withdrawClass = '';

        if (this.state.activeIndex === 'deposit') {
            depositClass = 'active';
            withdrawClass = '';
        } else {
            depositClass = '';
            withdrawClass = 'active';
        }

        let operateContent = '';
        let operationButton = '';


        if (!canOperate) {

            operateContent = <div className="col-12 mainButtonPoolContainer">
                {mainButton}
            </div>

        } else {

            if (this.state.activeIndex === 'deposit') {
                operationButton =
                    <button
                        className="depositButton btn btn-primary"
                        onClick={(event => {
                            event.preventDefault()
                            let amount
                            amount = this.state.amountInput;

                            this.props.deposit(poolValue.masterchef, poolValue.pid, amount)
                        })}
                    >
                        Deposit {poolName}
                    </button>;
            } else {
                operationButton =
                    <button
                        className="depositButton btn btn-primary"
                        onClick={(event => {
                            event.preventDefault()
                            let amount
                            amount = this.state.amountInput;

                            if (amount > 0) {
                                this.props.withdraw(poolValue.masterchef, poolValue.pid, amount)
                            }

                        })}
                    >
                        Withdraw {poolName} & Claim {earnTokenName}
                    </button>;
            }

        }

        operateContent =
            <div className="col-12 operatePoolContainer">
                <div className="operatorLinksRow row">
                    <div className="operatorLinksCol col-4">
                        <a href="javascript:void(0);" index='deposit' className={depositClass + ' depositLink'} onClick={ this.selectDeposit }>Deposit</a>

                        <span className="separatorOperatePoolContainer">|</span>

                        <a href="javascript:void(0);" index='withdraw' className={withdrawClass + ' withdrawLink'} onClick={ this.selectWithdraw }>Withdraw</a>
                    </div>
                    <div className="col-8 depositFeeContainer">
                        {poolDepositFeePercent}% {depositFeeText}
                    </div>
                </div>

                <div className="operatorFormContainerRow row">
                    <div className="operatorFormContainerCol col-12">
                        <div className="tokenInputContainer">

                            <div className="maxButtonContainer">
                                <a
                                    className="maxButton btn btn-primary"
                                    href=""
                                    onClick={(event => {
                                        event.preventDefault();

                                        let maxValueEther = 0;
                                        if (this.state.activeIndex === 'deposit') {
                                            maxValueEther = window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether')
                                        } else {
                                            maxValueEther = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')
                                        }

                                        this.setState({
                                            amountInput: maxValueEther
                                        })
                                    })}

                                >MAX</a>
                            </div>
                            {/*
                            <div className="poolNameTokenInputContainer">
                                {poolName}
                            </div>
                            */}

                            <input
                                className="tokenInput"
                                value={this.state.amountInput}
                                type="number"
                                onChange={(event => {

                                    let amount = event.target.value;

                                    if (parseFloat(amount) > parseFloat(this.state.inputMax)) {
                                        amount = this.state.inputMax;
                                    }

                                    if (amount < 0) {
                                        amount = 0;
                                    }

                                    this.setState({
                                        amountInput: amount
                                    })
                                })}

                                max={this.state.inputMax}
                            />

                        </div>
                        <div className="walletBalance">
                            WALLET BALANCE: {parseFloat(window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether')).toFixed(5).toString()} {poolName}
                        </div>

                        <div className="row">

                            <div className="col-6 yourBalanceContainer">
                                <span className="yourBalanceTitle">
                                    YOUR BALANCE
                                </span>
                                <span className="yourBalanceValue">
                                    ${userBalanceUSD}
                                </span>
                            </div>


                            <div className="col-6 operationButton text-right">
                                {mainButton}
                                {operationButton}
                            </div>

                        </div>

                    </div>
                </div>

            </div>


        let getOnPancakeLink = 'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + value.token1Contract;

        if (value.token2Name) {
            getOnPancakeLink = 'https://pancakeswap.finance/add/' + value.token1Contract + '/' + value.token2Contract;
        }


        let poolTotalLiquidity = 'Loading...'
        if (typeof value.tokensBalanceInBusd != 'undefined') {
            poolTotalLiquidity = parseFloat(value.tokensBalanceInBusd).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }



        let classActiveInactivePool = value.allocPoint > 0 ? 'poolActive' : 'poolInactive';
        let poolClass = 'row singleContainerPoolRow classActiveInactivePool ' + classActiveInactivePool;


        let urlPool = '/earn/' + poolTypeSlug + '/' + value.masterchef + '/' + value.pid;

        //<Link to={urlPool} key={'PoolBox' + value.masterchef + '-' + value.pid} className="col-12 singleContainerPool">



        return (
            <div className="col-12 singleContainerPool">
                <div className="row">

                    <a onClick={(event => {
                        event.preventDefault();
                        //this.state.closed = !this.state.closed;

                        //operationContainerPoolBox

                        this.setState({
                            closed: !this.state.closed
                        })

                    })} href="#" key={'PoolBox' + value.masterchef + '-' + value.pid} className="col-12">
                        <div className={poolClass}>

                            <div className="col-12 col-md-4 colPoolNameLogoTagAndLogoContainer">
                                <div className="row">
                                    <div className="col-3 firstColumnPoolBox iconsContainer">
                                        <img style={{width: '50px'}} src={require('../tokens-pools/' + tokensPools + '.png')} alt=""/>
                                    </div>
                                    <div className="col-9 secondColumnPoolBox">
                                        <div className="poolName">
                                            {poolNameTitle}
                                        </div>
                                        <div className="tagsContainer">
                                            {noFeeTag}
                                            {/*
                                            <span className="badge bg-primary">
                                                x{poolInfo.allocPoint / 100}
                                            </span>
                                            */}
                                        </div>
                                        {/*
                                        <div className="text-right activeBadgeContainer">
                                            {activeBadge}
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 colAprAndData">
                                <div className="row">

                                    <div className="col-6 thirdColumnPoolBox text-center colPoolBoxAprContainer">
                                        <div className="row">
                                            <div className="aprValueContainer col-12">
                                                {apy}%
                                            </div>
                                            <div className="aprTextContainer col-12">
                                                {aprOrApyTxt} {poolApr}%
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-6 colPoolBoxDataContainer">

                                        <div className="row">
                                            <div className="col-12">
                                                <span className="earnTextContainer">
                                                    Earn
                                                </span>
                                                <span className="earnValueContainer">
                                                    {earnTokenName}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="depositFeeTextContainer">
                                                    Balance
                                                </span>
                                                <span className="depositFeeValueContainer">
                                                    ${userBalanceUSD}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="detailsStakeTextContainer">
                                                    Total Liquidity
                                                </span>
                                                <span className="detailsTotalLiquidityTextValue">
                                                    ${poolTotalLiquidity}
                                                </span>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </div>



                            {/*
                            <div className="col-12 earnedContainer">
                                <div className="row">
                                    <div className="earnedTextContainer col-6">
                                        {earnTokenName} earned: <br />
                                        <div className="amoundEarned">
                                            {parseFloat(window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}
                                        </div>
                                    </div>
                                    <div className="earnedValueContainer col-6 text-right">
                                        {harvestButton}
                                        {compoundButton}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 stakedContainer">
                                <div className="row">
                                    <div className="stakedTextContainer col-6">
                                        <span className="stakedTokenName">{poolName}</span> <span className="textStaked">Staked:</span>
                                    </div>
                                    <div className="stakedValueContainer col-6 text-right">
                                        {parseFloat(window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                                    </div>
                                </div>
                            </div>
                            */}

                            {/*
                            {operateContent}
                            */}

                            {/*
                            <div className="col-12 detailsPoolContainer text-center">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="row">
                                            <div className="col-12 poolDetailsContainer text-left">

                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        {remainingBlocksText}
                                                    </div>
                                                </div>
                                                <div className="detailsTotalLiquidityContainer row">
                                                    <div className="col-6 detailsStakeTextContainer">
                                                        Total Liquidity:
                                                    </div>
                                                    <div className="col-6 detailsTotalLiquidityTextValue text-right">
                                                        ${poolTotalLiquidity}
                                                    </div>
                                                </div>
                                                <div className="detailsTotalLiquidityContainer row">
                                                    <div className="col-6 detailsStakeTextContainer">
                                                        1 x {poolName}:
                                                    </div>
                                                    <div className="col-6 detailsTotalLiquidityTextValue text-right">
                                                        ${poolStakingTokenPrice}
                                                    </div>
                                                </div>
                                                <div className="detailsViewOnBscScanContainer row">
                                                    <div className="col-12 detailsViewOnBscScanTextContainer">
                                                        <a target="_blank" href={'https://bscscan.com/token/' + value.lpToken}>View on BscScan</a>
                                                    </div>
                                                </div>
                                                <div className="detailsViewOnBscScanContainer row">
                                                    <div className="col-12 detailsViewOnBscScanTextContainer">
                                                        <a target="_blank" href={getOnPancakeLink}>Get {poolName} on pancakeswap.finance</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            */}
                        </div>

                    </a>
                </div>

                <div className="row">
                    <div className={"col-12 operationContainerPoolBox" + classAddOperationContainerPoolBox}>

                    <div className="row">

                        <div className="col-12 col-md-4 firstColumnPoolBoxOperation">

                            <div className="operationTextRow">
                                <span className="operationTextLabel">APY</span>
                                <span className="operationTextValue">{apy}%</span>
                            </div>

                            <div className="operationTextRow">
                                <span className="operationTextLabel">APR</span>
                                <span className="operationTextValue">{poolApr}%</span>
                            </div>

                            <div className="operationTextRow">
                                <span className="operationTextLabel">Deposit</span>
                                <span className="operationTextValue">{parseFloat(window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}</span>
                            </div>

                            <div className="operationTextRow">
                                <span className="operationTextLabel">Profit</span>
                                <span className="operationTextValue">{parseFloat(window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}</span>
                            </div>

                            <div className="operationButtonsRow">
                                {harvestButton}
                                {compoundButton}
                            </div>

                            <div className="operationViewContractRow">
                                <a className="operationViewContractLink" target="_blank" href="https://bscscan.com/">View Contract</a>
                            </div>

                            <div className="operationGetRow">
                                <a className="operationGetLink" target="_blank" href={getOnPancakeLink}>Get {poolName}</a>
                            </div>

                            <div className="operationSeeInfoRow">
                                <a className="operationSeeInfoLink" target="_blank" href={'https://bscscan.com/token/' + value.lpToken}>See {type} info</a>
                            </div>

                        </div>

                        <div className="col-12 col-md-8">

                            {operateContent}

                        </div>

                    </div>

                </div>
                </div>
            </div>
        )
    }
}

export default PoolBox;
