import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import PoolBox from "./PoolBox";
import {toast} from "react-toastify";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    withRouter
} from "react-router-dom";
import BigNumber from "bignumber.js";

class PoolSingle extends Component {

    constructor(props) {
        super(props);

        console.log('this.props.match.params');
        console.log(this.props.match.params);

        this.state = {
            activeTab: this.props.match.params.poolTypeSlug,
            numMasterchef: this.props.match.params.numMasterchef,
            pid: this.props.match.params.pid,

            activeIndex: 'deposit',
            amountInput: 0,
            inputMax: 0,
        }

        this.state.activeTab = this.state.activeTab;

        if (this.state.activeTab != 'farm' && this.state.activeTab != 'pool') {
            this.state.activeTab = 'farm';
        }

        this.state.activeTab = this.state.activeTab;
    }

    selectDeposit = () => {
        this.setState({
            activeIndex: 'deposit',
            amountInput: 0,
        })
    }

    selectWithdraw = () => {
        this.setState({
            activeIndex: 'withdraw',
            amountInput: 0,
        })
    }

    render() {

        let poolValue = null;

        for (const [index, value] of this.props.pools.entries()) {
            if (this.state.numMasterchef == value.masterchef && value.pid == this.state.pid) {
                poolValue = value;
                break;
            }
        }

        if (!poolValue) {
            return <div className="text-center">
                Loading
            </div>;
        } else {
            let tokensPools = poolValue.pid;

            let pid = poolValue.pid;

            let totalAllocPoint = this.props.getTotalAllocPointByMasterchefNum(poolValue.masterchef);

            let poolInfo = this.props.getPoolInfo(poolValue.masterchef, pid);

            let depositFeeText = this.props.getDepositFeeTextByMasterchefNum(poolValue.masterchef);

            let depositFeePercent = -1;
            if (poolInfo.depositFeeBP) {
                depositFeePercent = poolInfo.depositFeeBP / 100
            }

            let poolDepositFeePercent;
            if (depositFeePercent >= 0) {
                poolDepositFeePercent = depositFeePercent;
            } else {
                poolDepositFeePercent = 'Loading...';
            }

            let backLink = '/earn/farms';

            if (this.state.activeTab == 'pool') {
                backLink = '/earn/pools';
            }

            if (poolValue.masterchef != 0) {
                tokensPools = poolValue.masterchef + '-' + poolValue.pid;
            }

            let earnTokenName = this.props.getEarnTokenNameByMasterchefNum(poolValue.masterchef);

            let poolName = poolValue.token1Name;

            let type = 'token';
            let typeName = '';
            let aprOrApyTxt = 'APR';
            let poolTypeSlug = 'pool';
            if (poolValue.token2Name) {
                poolName += '-' + poolValue.token2Name;
                //image2 += require('../tokens/' + value.token2Name.toLowerCase() + '.png');
                type = 'lp';
                typeName = 'LP';
                //aprOrApyTxt = 'APY';
                poolTypeSlug = 'farm';
            }

            let apr = 0;


            let tokenPerBlock = this.props.getTokensPerBlockByMasterchefNum(poolValue.masterchef);

            let multiplier = 1;

            let stakingTokenPrice = poolValue.lpPrice;

            let poolStakingTokenPrice = 'Loading...';
            if (stakingTokenPrice) {
                poolStakingTokenPrice = parseFloat(stakingTokenPrice).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }

            let rewardTokenPrice = this.props.getRewardTokenPriceByMasterchefNum(poolValue.masterchef);

            let totalStaked = poolValue.tokensBalance;
            if (totalStaked < 0.01) {
                totalStaked = 0.0001
            }
            let tokenPerBlockPool = tokenPerBlock * poolInfo.allocPoint / totalAllocPoint;

            if (type === 'token') {

                apr = this.props.getPoolApy(
                    stakingTokenPrice,
                    rewardTokenPrice,
                    totalStaked,
                    tokenPerBlockPool,
                );
            } else {

                let poolWeight = poolInfo.allocPoint / totalAllocPoint;

                let poolLiquidityUsd = poolValue.tokensBalanceInBusd;
                if (poolLiquidityUsd < 0.01) {
                    poolLiquidityUsd = 0.001;
                }

                apr = this.props.getFarmApy(
                    poolWeight,
                    rewardTokenPrice,
                    poolLiquidityUsd,
                    new BigNumber(tokenPerBlock)
                );
            }


            let poolApr = 0;
            if (apr) {
                poolApr = parseFloat(apr).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                poolApr = 'Loading...';
            }

            let apy = '...';
            if (poolApr != 'Loading...') {
                /*console.log('apr');
                console.log(apr);*/
                apy = (Math.pow(1 + (apr / 100) / 365, 365) - 1) * 100;
                apy = parseFloat(apy).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            let mainButton = <button
                className="btn btn-primary unlockWalletButton"
                onClick={(event => {
                    event.preventDefault();
                    this.props.connectWalletConnect(true);
                })}
            >
                Connect Wallet
            </button>;

            let harvestButton =
                <button className="btn btn-secondary disabled harvestButton" disabled="disabled">
                    Claim
                </button>;

            let compoundButton = '';


            if (poolValue.pid === 0 && poolValue.masterchef != 2) {
                compoundButton = <button className="btn btn-secondary disabled compoundButton" disabled="disabled">
                    Compound
                </button>;
            }

            let canOperate = false;

            let dataUserPool = this.props.getDataUserPool(poolValue.masterchef, poolValue.pid);
            let getDataUserBalance = this.props.getDataUserBalance(poolValue.masterchef, poolValue.pid);


            if (this.props.account !== '0x0') {

                if (dataUserPool.approvedQty != 0) {

                    mainButton = ''
                    canOperate = true;

                    if (this.state.activeIndex == 'deposit') {

                        let userBalance = parseFloat(window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether'));

                        if (userBalance != this.state.inputMax) {
                            this.setState({
                                inputMax: userBalance
                            })
                        }

                    } else {

                        let stakedAmountMax = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether');

                        if (stakedAmountMax != this.state.inputMax) {
                            this.setState({
                                inputMax: stakedAmountMax
                            })
                        }

                    }

                } else {

                    mainButton = <button
                        className="btn btn-primary approveButton"
                        onClick={(event => {
                            event.preventDefault();
                            this.props.approveContract(poolValue.masterchef, type, poolValue.lpToken);
                        })}
                    >
                        Approve {poolName}
                    </button>
                }

                if (dataUserPool.rewardDebt > 0) {
                    harvestButton =
                        <button
                            className="btn btn-primary harvestButton"
                            onClick={(event => {
                                event.preventDefault()
                                let amount
                                amount = '0';

                                this.props.withdraw(poolValue.masterchef, poolValue.pid, amount)
                            })}
                        >
                            Claim
                        </button>;


                    if (poolValue.pid === 0  && poolValue.masterchef != 2) {
                        compoundButton = <button
                            className="btn btn-primary compoundButton"
                            onClick={(event => {
                                event.preventDefault()
                                let amount
                                amount = window.web3.utils.fromWei(dataUserPool.rewardDebt, 'Ether');

                                this.props.deposit(poolValue.masterchef, poolValue.pid, amount);
                            })}
                        >
                            Compound
                        </button>
                    }
                }
            }

            if (this.state.activeIndex === 'deposit') {
                let userBalance = window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether');
                if (this.state.amountInput > userBalance) {
                    this.state.maxAmountInput = userBalance;
                }
            } else {
                let stakedAmount = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether');
                if (this.state.amountInput > stakedAmount) {
                    this.state.maxAmountInput = stakedAmount;
                }
            }

            let depositClass = '';
            let withdrawClass = '';

            if (this.state.activeIndex === 'deposit') {
                depositClass = 'active';
                withdrawClass = '';
            } else {
                depositClass = '';
                withdrawClass = 'active';
            }

            let operateContent = '';
            let operationButton = '';


            if (!canOperate) {

                operateContent = <div className="col-12 mainButtonPoolContainer">
                    {mainButton}
                </div>

            } else {

                if (this.state.activeIndex === 'deposit') {
                    operationButton =
                        <button
                            className="depositButton btn btn-primary"
                            onClick={(event => {
                                event.preventDefault()
                                let amount
                                amount = this.state.amountInput;

                                this.props.deposit(poolValue.masterchef, poolValue.pid, amount)
                            })}
                        >
                            Deposit {poolName}
                        </button>;
                } else {
                    operationButton =
                        <button
                            className="depositButton btn btn-primary"
                            onClick={(event => {
                                event.preventDefault()
                                let amount
                                amount = this.state.amountInput;

                                if (amount > 0) {
                                    this.props.withdraw(poolValue.masterchef, poolValue.pid, amount)
                                }

                            })}
                        >
                            Withdraw {poolName} & Claim {earnTokenName}
                        </button>;
                }

            }

            operateContent =
                <div className="col-12 operatePoolContainer">
                    <div className="operatorLinksRow row">
                        <div className="operatorLinksCol col-4">
                            <a href="javascript:void(0);" index='deposit' className={depositClass + ' depositLink'} onClick={ this.selectDeposit }>Deposit</a>

                            <span className="separatorOperatePoolContainer">|</span>

                            <a href="javascript:void(0);" index='withdraw' className={withdrawClass + ' withdrawLink'} onClick={ this.selectWithdraw }>Withdraw</a>
                        </div>
                        <div className="col-8 depositFeeContainer">
                            {poolDepositFeePercent}% {depositFeeText}
                        </div>
                    </div>

                    <div className="operatorFormContainerRow row">
                        <div className="operatorFormContainerCol col-12">
                            <div className="tokenInputContainer">

                                <div className="maxButtonContainer">
                                    <a
                                        className="maxButton btn btn-primary"
                                        href=""
                                        onClick={(event => {
                                            event.preventDefault();

                                            let maxValueEther = 0;
                                            if (this.state.activeIndex === 'deposit') {
                                                maxValueEther = window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether')
                                            } else {
                                                maxValueEther = window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')
                                            }

                                            this.setState({
                                                amountInput: maxValueEther
                                            })
                                        })}

                                    >MAX</a>
                                </div>

                                <div className="poolNameTokenInputContainer">
                                    {poolName}
                                </div>

                                <input
                                    className="tokenInput"
                                    value={this.state.amountInput}
                                    type="number"
                                    onChange={(event => {

                                        let amount = event.target.value;

                                        if (amount > this.state.inputMax) {
                                            amount = this.state.inputMax;
                                        }

                                        if (amount < 0) {
                                            amount = 0;
                                        }

                                        this.setState({
                                            amountInput: amount
                                        })
                                    })}

                                    max={this.state.inputMax}
                                />

                            </div>
                            <div className="walletBalance">
                                WALLET BALANCE: {parseFloat(window.web3.utils.fromWei(getDataUserBalance.toString(), 'Ether')).toFixed(5).toString()} {poolName}
                            </div>

                            <div className="col-12 yourBalanceContainer">
                                <div className="yourBalanceTitle">
                                    YOUR BALANCE
                                </div>
                                <div className="yourBalanceValue">
                                    $0.000
                                </div>
                            </div>


                            <div className="operationButton">
                                {mainButton}
                                {operationButton}
                            </div>
                        </div>
                    </div>

                </div>

            return (
                <div className="row rowPoolSingle">
                    <div className="col-12 colPoolSingleGlobal">
                        <div className="row">
                            <div className="col-12 backLinkContainer">
                                <Link to={backLink}>&#8592; Back</Link>
                            </div>
                            <div className="col-12 poolLogoContainer text-center">
                                <img style={{width: '50px'}} src={require('../tokens-pools/' + tokensPools + '.png')}
                                     alt=""/>
                            </div>
                            <div className="col-12 poolNameContainer text-center">
                                {poolName}
                            </div>
                            <div className="col-12 poolDataContainer">

                                <div className="row rowPoolDataContainer">
                                    <div className="col-4 firstColumnPoolData">
                                        APY
                                    </div>
                                    <div className="col-4 secondColumnPoolData">
                                        {apy}%
                                    </div>
                                    <div className="col-4 thirdColumnPoolData">

                                    </div>
                                </div>

                                <div className="row rowPoolDataContainer">
                                    <div className="col-4 firstColumnPoolData">
                                        APR
                                    </div>
                                    <div className="col-4 secondColumnPoolData">
                                        {poolApr}%
                                    </div>
                                    <div className="col-4 thirdColumnPoolData">

                                    </div>
                                </div>

                                <div className="row rowPoolDataContainer">
                                    <div className="col-4 firstColumnPoolData">
                                        Deposit
                                    </div>
                                    <div className="col-4 secondColumnPoolData">
                                        {parseFloat(window.web3.utils.fromWei(dataUserPool.stakedAmount, 'Ether')).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                                    </div>
                                    <div className="col-4 thirdColumnPoolData">
                                        {poolName}
                                    </div>
                                </div>

                                <div className="row rowPoolDataContainer">
                                    <div className="col-4 firstColumnPoolData">
                                        Profit
                                    </div>
                                    <div className="col-4 secondColumnPoolData">
                                        {parseFloat(window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether')).toFixed(7).toString().replace(/\B(?=(\d{7})+(?!\d))/g, ",")}
                                    </div>
                                    <div className="col-4 thirdColumnPoolData">
                                        {harvestButton}
                                        {compoundButton}
                                    </div>
                                </div>

                                <div className="row rowPoolDataContainer">
                                    <div className="col-4 firstColumnPoolData">
                                        Contract
                                    </div>
                                    <div className="col-4 secondColumnPoolData">

                                    </div>
                                    <div className="col-4 thirdColumnPoolData">
                                        <a target="_blank" href="https://bscscan.com/">View contract</a>
                                    </div>
                                </div>

                            </div>

                            {operateContent}

                        </div>

                    </div>
                </div>
            );
        }

    }
}

export default withRouter(PoolSingle);
