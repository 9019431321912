import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class TheGameGetCards extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="row rowReferral">
                <div className="col-12 gameContainerGetCards">
                    <h2>Get Cards!</h2>
                </div>
            </div>
        );
    }
}

export default withRouter(TheGameGetCards);
