import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class KycPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="row rowReferral">
                <div className="col-12 loginPageContainer">
                    <iframe id="editProfileIframe" width="100%" height="1500" src="https://kyc.cryptogirl.finance/user/edit-profile" frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
        );
    }
}

export default withRouter(KycPage);
