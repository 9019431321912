import React, { Component } from 'react'
import Calculator from '../img/calculator.svg'
import PoolBox from "./PoolBox";
import Menu from "./Menu";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import { withRouter } from "react-router-dom";

class Earn extends Component {

    constructor(props) {
        super(props);

        let paramEarnTypeSlug = this.props.match.params.earnTypeSlug;

        if (paramEarnTypeSlug != 'farms' && paramEarnTypeSlug != 'pools') {
            paramEarnTypeSlug = 'farms';
        }

        this.state = {
            activeTab: paramEarnTypeSlug,
        }
    }

    render() {

        let tvl = '...';
        if (this.props.tvl > 0) {
            tvl = parseFloat(this.props.tvl).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        const pools = []

        for (const [index, value] of this.props.pools.entries()) {
            /*
            let showBox = false;

            if (this.props.native == 1 && (value.token1Name == 'SLION' || value.token1Name == 'MLION'  || value.token1Name == 'BURN SLION')) {
                showBox = true;
            } else if(this.props.native == 0 && (value.token1Name != 'SLION' && value.token1Name != 'MLION' && value.token1Name != 'BURN SLION')) {
                showBox = true;
            }
            */

            let showBox = true;

            if (
                (
                    this.state.activeTab == 'pools' &&

                    !value.token2Name
                    &&
                    showBox
                )

                ||

                (
                    this.state.activeTab == 'farms' &&

                    value.token2Name
                    &&
                    showBox
                )

            ) {
                pools.push(
                    <PoolBox
                        key={'Pool' + value.masterchef + '-' + value.pid}
                        index={index}
                        value={value}

                        approveContract={this.props.approveContract.bind(this)}
                        deposit={this.props.deposit.bind(this)}
                        withdraw={this.props.withdraw.bind(this)}
                        connectWalletConnect={this.props.connectWalletConnect.bind(this)}
                        hasApprovedContract={this.props.hasApprovedContract.bind(this)}

                        getDataUserPool={this.props.getDataUserPool.bind(this)}
                        getDataUserBalance={this.props.getDataUserBalance.bind(this)}
                        getPoolInfo={this.props.getPoolInfo.bind(this)}
                        getLiquidityPools={this.props.getLiquidityPools.bind(this)}
                        handleOpenModal={this.props.handleOpenModal.bind(this)}
                        setAprToModal={this.props.setAprToModal.bind(this)}
                        getFarmApy={this.props.getFarmApy.bind(this)}
                        getPoolApy={this.props.getPoolApy.bind(this)}
                        getTokenPriceBySymbol={this.props.getTokenPriceBySymbol.bind(this)}
                        getTokenPriceByContract={this.props.getTokenPriceByContract.bind(this)}
                        getPairPriceByContract={this.props.getPairPriceByContract.bind(this)}

                        getTotalAllocPointByMasterchefNum={this.props.getTotalAllocPointByMasterchefNum.bind(this)}
                        getTokensPerBlockByMasterchefNum={this.props.getTokensPerBlockByMasterchefNum.bind(this)}
                        getRewardTokenPriceByMasterchefNum={this.props.getRewardTokenPriceByMasterchefNum.bind(this)}
                        getEarnTokenNameByMasterchefNum={this.props.getEarnTokenNameByMasterchefNum.bind(this)}
                        getDepositFeeTextByMasterchefNum={this.props.getDepositFeeTextByMasterchefNum.bind(this)}

                        account={this.props.account}
                        ourTokenName={this.props.ourTokenName}
                        ourTokenPrice={this.props.ourTokenPrice}
                        slionPerBlockEther={this.props.slionPerBlockEther}
                        totalAllocPoint={this.props.totalAllocPoint}
                        ourSecondTotalAllocPoint={this.props.ourSecondTotalAllocPoint}

                        ourSecondTokenName={this.props.ourSecondTokenName}
                        ourSecondTokenPrice={this.props.ourSecondTokenPrice}
                        ourSecondTokenContract={this.props.ourSecondTokenContract}
                        mlionPerBlockEther={this.props.mlionPerBlockEther}

                        currentBlock={this.props.currentBlock}
                        bnbPrice={this.props.bnbPrice}
                    />
                )
            }
        }

        let farmsButtonClass = '';
        let poolsButtonClass = '';

        if (this.state.activeTab == 'farms') {
            farmsButtonClass = 'active';
        }

        if (this.state.activeTab == 'pools') {
            poolsButtonClass = 'active';
        }

        return (
            <div className="row rowPools">
                <div className="col-12 text-center colPools">
                    <h2 id="ourPoolsSubtitle">Earn useful tokens in our game</h2>
                    {/*
                    <div className="ourPoolsDescription">
                        Stake tokens to earn {this.props.ourXlionTokenName} and {this.props.ourSecondTokenName}
                    </div>
                    <div className="ourPoolsDescription">
                        🦁 Part of the deposit fees is used to buyback and burn, in order to maintain the price of our tokens
                    </div>
                    */}
                </div>
                <div className="col-12 col-md-6 containerTVL">
                    <div className="numberContainerTVL">
                        ${tvl}
                    </div>
                    <div className="textContainerTVL">
                        Total deposit value at cryptogirl
                    </div>
                </div>
                <div className="col-12 col-md-6 detailsMarketCapAndProfits">
                    <div className="row rowMarketCap">
                        <div className="col-6 col-md-8 numberMarketCap">
                            ${parseFloat(this.props.marketCap).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-6 col-md-4 textMarketCap">
                            $CGIRL<br />
                            Market Cap
                        </div>
                    </div>
                    <div className="row rowMonthlyProfits">
                        <div className="col-6 col-md-8 numberMonthlyProfits">
                            ${parseFloat(this.props.ourSecondTokenMarketCap).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-6 col-md-4 textMonthlyProfits">
                            $EGIRL<br />
                            Market Cap
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="buttonsFarmPoolContainer">
                        <Link
                            className={'btn btn-primary ' + farmsButtonClass}
                            to="/earn/farms"
                            onClick={(event => {
                              //event.preventDefault();

                              this.state.activeTab = 'farms';
                            })}
                        >Farms</Link>
                        <Link
                            className={'btn btn-primary ' + poolsButtonClass}
                            to="/earn/pools"
                            onClick={(event => {
                              //event.preventDefault();

                                this.state.activeTab = 'pools';

                            })}
                        >Pools</Link>
                    </div>
                </div>
                <div className="col-12">
                    <div className="row" id="poolsContainer">
                        {pools}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Earn);
