import React, { Component } from 'react'
import TweetEmbed from 'react-tweet-embed';

class Home extends Component {

    render() {

        let ourTokenPrice = '...';

        var stateOurTokenPrice = this.props.ourTokenPrice

        if (!isNaN(stateOurTokenPrice)) {
            ourTokenPrice = stateOurTokenPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let ourSecondTokenPrice = '...';

        var stateOurSecondTokenPrice = this.props.ourSecondTokenPrice

        if (!isNaN(stateOurSecondTokenPrice)) {
            ourSecondTokenPrice = stateOurSecondTokenPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let ourTokenXlionPrice = '...';
        var stateOurTokenXlionPrice = this.props.ourTokenXlionPrice
        if (!isNaN(stateOurTokenXlionPrice)) {
            ourTokenXlionPrice = stateOurTokenXlionPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let tvl = '...';
        if (this.props.tvl > 0) {
            tvl = parseFloat(this.props.tvl).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        let poolAndReward = [];
        let poolAndRewardMlion = [];

        let totalTokenToHavestEther = 0;
        let totalTokenToHavestEtherMlion = 0;
        let totalTokenToHavestEtherXlion = 0;

        for (const [index, value] of this.props.pools.entries()) {

                let dataUserPool = this.props.getDataUserPool(value.masterchef, value.pid);

                let rewardEther = window.web3.utils.fromWei(dataUserPool.rewardDebt.toString(), 'Ether');

                if (dataUserPool.rewardDebt > 0) {
                    if (value.masterchef == 4) {
                        poolAndReward.push({
                            pid: value.pid,
                            rewardEther: rewardEther,
                        })

                        totalTokenToHavestEtherXlion = parseFloat(totalTokenToHavestEtherXlion) + parseFloat(rewardEther);
                    } else if(value.masterchef == 1) {
                        poolAndRewardMlion.push({
                            pid: value.pid,
                            rewardEther: rewardEther,
                        })

                        totalTokenToHavestEtherMlion = parseFloat(totalTokenToHavestEtherMlion) + parseFloat(rewardEther);
                    }
                }

        }

        let harvestAllButton =
            <button className="btn btn-secondary disabled harvestButton" disabled="disabled">
                Harvest all
            </button>;

        let harvestAllButtonMlion =
            <button className="btn btn-secondary disabled harvestButton" disabled="disabled">
                Harvest all
            </button>;

        if (poolAndReward.length > 0) {
            harvestAllButton =
                <button
                    className="btn btn-primary harvestButton"
                    onClick={(event => {
                        event.preventDefault()

                        var _this = this;

                        poolAndReward.forEach(function(data) {
                            let pid = data.pid;
                            //let reward = data.reward;
                            //let rewardEth = window.web3.utils.fromWei(reward, 'Ether');

                            //console.log(pid, rewardEth)

                            //_this.props.withdraw(pid, rewardEth);
                            _this.props.withdraw(4, pid, 0);
                        });


                    })}
                >
                    Harvest all ({poolAndReward.length})
                </button>;
        }

        if (poolAndRewardMlion.length > 0) {
            harvestAllButtonMlion =
                <button
                    className="btn btn-primary harvestButton"
                    onClick={(event => {
                        event.preventDefault()

                        var _this = this;

                        poolAndRewardMlion.forEach(function(data) {
                            let pid = data.pid;
                            //let reward = data.reward;
                            //let rewardEth = window.web3.utils.fromWei(reward, 'Ether');

                            //console.log(pid, rewardEth)

                            //_this.props.withdraw(pid, rewardEth);
                            _this.props.withdraw(1, pid, 0);
                        });


                    })}
                >
                    Harvest all ({poolAndRewardMlion.length})
                </button>;
        }

        return (
            <div className="row homeContainer">

                <div className="col-12 colHomeHeaderContainer">

                    <div className="row rowHomeHeaderContainer">

                        <div className="col-12 totalValueLockedValue text-center">
                            ${tvl}
                        </div>

                        <div className="col-12 totalValueLockedText text-center">
                            Total Value Locked
                        </div>

                        <div className="col-12 welcomeCol">
                            {/*<h1 id="welcomeTo">Make money with {this.props.defiName}</h1>*/}
                            <h1 id="welcomeTo">NFT Game and Defi</h1>
                            <div className="descriptionProject text-center">
                                <p className="descriptionProject1">Earn our tokens staking your tokens or playing in our game</p>
                                {/*<p className="descriptionProject2">Fully Compliant dApp | High APR | Burning Mechanisms</p>*/}
                            </div>
                        </div>

                    </div>

                </div>


                <div className="col-12 col-md-6 tokenInfoBox">
                    <div className="row">
                        <div className="col-12 colTokenIcon">
                            <a onClick={(event => {
                                event.preventDefault()
                                this.props.registerTokenMetamask();
                            })} href="">
                                <img className="tokenIconHome" src={require('../img/energycryptogirl.svg')} alt=""/>
                            </a>
                        </div>
                        <div className="col-12 tokenInfoBoxTitleContainer">
                            <h2 className="tokenInfoBoxTitle text-center">{this.props.ourTokenName}</h2>
                        </div>
                        <div className="col-12 tokenInfoBoxPrice text-center">
                            $ {ourTokenPrice}
                        </div>
                        <div className="col-12 tokenInfoBoxPricePerToken text-center">
                            Price per token
                        </div>
                        <div className="col-12 tokenInfoBoxTotalInCirculationValue text-center">
                            {parseFloat(this.props.tokenTotalInCirculation).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-12 tokenInfoBoxTotalInCirculationText text-center">
                            Total in circulation
                        </div>
                        <div className="col-12 tokenInfoBoxTotalSupplyValue text-center">
                            {parseFloat(this.props.tokenTotalSupply).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-12 tokenInfoBoxTotalSupplyText text-center">
                            Total supply
                        </div>
                        <div className="col-12 tokenInfoBoxBuyTokenContainer text-center">
                            <a className="btn-block btn-primary tokenInfoBoxBuyTokenButton" href="">Buy {this.props.ourTokenName}</a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 tokenInfoBox">
                    <div className="row">
                        <div className="col-12 colTokenIcon">
                            <img className="tokenIconHome" src={require('../img/cryptogirl.svg')} alt=""/>
                        </div>
                        <div className="col-12 tokenInfoBoxTitleContainer">
                            <h2 className="tokenInfoBoxTitle text-center">{this.props.ourSecondTokenName}</h2>
                        </div>
                        <div className="col-12 tokenInfoBoxPrice text-center">
                            $ {ourSecondTokenPrice}
                        </div>
                        <div className="col-12 tokenInfoBoxPricePerToken text-center">
                            Price per token
                        </div>
                        <div className="col-12 tokenInfoBoxTotalInCirculationValue text-center">
                            {parseFloat(this.props.ourSecondTokenTotalInCirculation).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-12 tokenInfoBoxTotalInCirculationText text-center">
                            Total in circulation
                        </div>
                        <div className="col-12 tokenInfoBoxTotalSupplyValue text-center">
                            {parseFloat(this.props.ourSecondTokenTotalSupply).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </div>
                        <div className="col-12 tokenInfoBoxTotalSupplyText text-center">
                            Total supply
                        </div>
                        <div className="col-12 tokenInfoBoxBuyTokenContainer text-center">
                            <a className="btn-block btn-primary tokenInfoBoxBuyTokenButton" href="">Buy {this.props.ourSecondTokenName}</a>
                        </div>
                    </div>
                </div>

                {/*
                <div className="col-12 col-md-6 text-center ourTokenContainer">
                    <div className="row">

                        <div className="col-12">
                            <div id="makeMoneyContainer">
                                XtraLion
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-2 ourLogoTokenContainer">
                                    <img style={{width: '50px'}} src={require('../tokens-pools/4-0.png')} alt=""/>
                                </div>
                                <div className="col-6 ourTokenNameAndPrice">
                                    <span className="ourTokenName">
                                        {this.props.ourXlionTokenName}
                                        <span className="badge bg-success">New</span>
                                    </span>
                                    <span className="ourTokenPrice">
                                        {ourTokenXlionPrice}
                                    </span>
                                </div>
                                <div className="col-4 buyOurTokenContainer">
                                    <a
                                        className="buyTokenButton btn btn-primary"
                                        href={'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + this.props.ourXlionTokenContract }
                                        target="_blank"
                                    >
                                        Buy {this.props.ourXlionTokenName}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="containerOurTokenToHarvest col-8">
                                    <span className="ourTokenToHarvest">
                                        {this.props.ourXlionTokenName}'s to Harvest
                                    </span>
                                    <span className="ourTokenToHarvestValue">
                                        {parseFloat(totalTokenToHavestEtherXlion).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                                    </span>
                                </div>
                                <div className="ourTokenToHarvestButtonContainer col-4">
                                    {harvestAllButton}
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <span className="tokenInYourWallet">
                                {this.props.ourXlionTokenName}'s in your wallet
                            </span>
                            <span className="tokenInYourWalletValue">
                                {parseFloat(this.props.xlionTokenTokensInYourWallet.toString()).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                            </span>
                        </div>



                        <div className="col-12">
                            <div id="makeMoneyContainer">
                                MegaLion
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-2 ourLogoTokenContainer">
                                    <img style={{width: '50px'}} src={require('../tokens-pools/1-0.png')} alt=""/>
                                </div>
                                <div className="col-6 ourTokenNameAndPrice">
                                    <span className="ourTokenName">
                                        {this.props.ourSecondTokenName}
                                    </span>
                                    <span className="ourTokenPrice">
                                        {ourSecondTokenPrice}
                                    </span>
                                </div>
                                <div className="col-4 buyOurTokenContainer">
                                    <a
                                        className="buyTokenButton btn btn-primary"
                                        href={'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + this.props.ourSecondTokenContract }
                                        target="_blank"
                                    >
                                        Buy {this.props.ourSecondTokenName}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="containerOurTokenToHarvest col-8">
                                    <span className="ourTokenToHarvest">
                                        {this.props.ourSecondTokenName}'s to Harvest
                                    </span>
                                    <span className="ourTokenToHarvestValue">
                                        {parseFloat(totalTokenToHavestEtherMlion).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                                    </span>
                                </div>
                                <div className="ourTokenToHarvestButtonContainer col-4">
                                    {harvestAllButtonMlion}
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <span className="tokenInYourWallet">
                                {this.props.ourSecondTokenName}'s in your wallet
                            </span>
                            <span className="tokenInYourWalletValue">
                                {parseFloat(this.props.secondTokenTokensInYourWallet.toString()).toFixed(5).toString().replace(/\B(?=(\d{5})+(?!\d))/g, ",")}
                            </span>
                        </div>

                        <div className="col-12">
                            <div id="makeMoneyContainer">
                                SuperLion
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-2 ourLogoTokenContainer">
                                    <img style={{width: '50px'}} src={require('../tokens-pools/0.png')} alt=""/>
                                </div>
                                <div className="col-6 ourTokenNameAndPrice">
                                    <span className="ourTokenName">
                                        {this.props.ourTokenName}
                                    </span>
                                    <span className="ourTokenPrice">
                                        {ourTokenPrice}
                                    </span>
                                </div>
                                <div className="col-4 buyOurTokenContainer">
                                    <a
                                        className="buyTokenButton btn btn-primary"
                                        href={'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + this.props.ourTokenContract }
                                        target="_blank"
                                    >
                                        Buy {this.props.ourTokenName}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12 col-md-6 tokenStats">

                    <div className="tokenStatsTitle">
                        {this.props.ourXlionTokenName} Stats
                    </div>
                    <table className="tokenStatsTable">
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourXlionTokenName} Supply</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.ourXlionTokenTotalSupply).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourXlionTokenName} Burned</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.totalXlionTokenBurned).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Market Cap</td>
                            <td className="tokenStatsTableValue text-right">${parseFloat(this.props.ourXlionTokenMarketCap).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">New {this.props.ourXlionTokenName} per block</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.xlionPerBlockEther).toFixed(5)}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">
                                Max Transaction Amount
                                ({parseFloat(this.props.maxTransferAmountRatePercent).toFixed(2)}%)
                            </td>
                            <td className="tokenStatsTableValue text-right">

                                {parseFloat(this.props.xlionTokenMaxTransferAmountRate).toFixed(4)}
                            </td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Transfer fee (burn fee)</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.xlionTokentransferTaxRate).toFixed(2)}%</td>
                        </tr>
                    </table>

                    <div className="tokenStatsTitle">
                        {this.props.ourSecondTokenName} Stats
                    </div>
                    <table className="tokenStatsTable">
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourSecondTokenName} Supply</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.ourSecondTokenTotalSupply).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourSecondTokenName} Burned</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.totalSecondTokenBurned).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Market Cap</td>
                            <td className="tokenStatsTableValue text-right">${parseFloat(this.props.ourSecondTokenMarketCap).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">New {this.props.ourSecondTokenName} per block</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.mlionPerBlockEther).toFixed(5)}</td>
                        </tr>
                    </table>

                    <div className="tokenStatsTitle">
                        {this.props.ourTokenName} Stats
                    </div>
                    <table className="tokenStatsTable">
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourTokenName} Supply</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.tokenTotalSupply).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Total {this.props.ourTokenName} Burned</td>
                            <td className="tokenStatsTableValue text-right">{parseFloat(this.props.totalTokenBurned).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">Market Cap</td>
                            <td className="tokenStatsTableValue text-right">${parseFloat(this.props.marketCap).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                        <tr>
                            <td className="tokenStatsTableLabel">New {this.props.ourTokenName} per block</td>

                            <td className="tokenStatsTableValue text-right">0 (no more printing)</td>
                        </tr>
                    </table>

                    <div className="totalValueLockedContainer">
                        <div className="totalValueLockedTitle">
                            Total Value Locked (TVL)
                        </div>
                        <div className="totalValueLockedValue">
                            ${tvl}
                        </div>
                        <div className="totalValueLockedFooter">
                            <small>Across all Farms and Pools of {this.props.defiName}</small>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 twitterContainer text-center">
                    <div className="AnnouncementsTitle">
                        Announcements
                    </div>
                    <div className="twitterEmbed">
                        <a target="_blank" className="twitter-timeline" width="100%" height="350" href="https://twitter.com/SuperLionBSC?ref_src=twsrc%5Etfw">Tweets
                            by SuperLionDefi</a>
                        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    </div>
                </div>
                */}
            </div>
        );
    }
}

export default Home;
