import React, { Component } from 'react'
import farmer from '../farmer.png'
import {BrowserRouter as Router, Link} from "react-router-dom";
import LogoCryptoGirl from '../img/logocrypto.svg';

import {Nav, Navbar, NavDropdown, MenuItem,  Tabs, ButtonToolbar, Button, Table, ButtonGroup, Row, Col, Grid, Panel, FormGroup, FormControl} from 'react-bootstrap';


class Menu extends Component {

  render() {
      /*
      let account = <button
        className="btn btn-primary"
        onClick={(event => {
            event.preventDefault();
            this.props.connectWalletConnect();
        })}
      >
        Connect Wallet
      </button>;
      */

      let logoutButton = '';

      let account = <button
          className="btn btn-primary"
          onClick={(event => {
              event.preventDefault();
              this.props.connectWalletConnect(true);
          })}
      >
          Connect Wallet
      </button>;

      if (this.props.account !== '0x0') {
          account = this.props.account.substring(0,6) + '...' + this.props.account.substr(this.props.account.length - 4);

          account = <a id="accountButton" className="btn btn-primary" href="" onClick={(event => {
              event.preventDefault();
              this.props.setAccountModal(this.props.account)
              this.props.handleOpenModal()
          })}>
              {account}
          </a>

          logoutButton = <button
              className="btn btn-primary"
              onClick={(event => {
                  event.preventDefault();
                  this.props.disconnectWallet();
              })}
          >
            Logout
          </button>
      }

      let ourTokenPrice = '(Loading...)';

      var stateOurTokenPrice = this.props.ourTokenPrice

      if (!isNaN(stateOurTokenPrice)) {
          ourTokenPrice = '$' + stateOurTokenPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      let ourSecondTokenPrice = '(Loading...)';
      let stateOurSecondTokenPrice = this.props.ourSecondTokenPrice;

      if (!isNaN(stateOurSecondTokenPrice)) {
          ourSecondTokenPrice = '$' + stateOurSecondTokenPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      let ourTokenXlionPrice = '(Loading...)';
      let stateOurTokenXlionPrice = this.props.ourTokenXlionPrice;

      if (!isNaN(stateOurTokenXlionPrice)) {
          ourTokenXlionPrice = '$' + stateOurTokenXlionPrice.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      /*
      let loginSignInBox = <Nav id="loginSignInBox">
          <Nav>
              <a target="_blank" id="loginLink" href="https://auth.xtralion.finance/login">Log in</a>
          </Nav>
          <Nav>
              <a id="signinLink" target="_blank" href="https://auth.xtralion.finance/register" >Register</a>
          </Nav>
      </Nav>;
      */

      let loginSignInBox = <Nav id="loginSignInBox">
          <Nav className="d-none d-lg-block">
              <Link id="loginLink" to="/login">Log in</Link>
          </Nav>
          <Nav className="d-none d-lg-block">
              <Link id="signinLink" to="/register" >Register</Link>
          </Nav>
          <Nav className="d-block d-lg-none">
              <Link id="loginLink" to="/login">Log in</Link>
              <span className="loginTextSeparator">/</span>
              <Link id="signinLink" to="/register" >Register</Link>
          </Nav>
      </Nav>;

      if (this.props.userEmail) {
          loginSignInBox =
              <Nav id="loginSignInBox">
                  <Nav>
                      <span className="helloUser">
                          <span id="userEmail">
                            <NavDropdown title={this.props.userEmail}>
                                <NavDropdown.Item href="/kyc">
                                    KYC
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/edit-profile">
                                    Change password
                                </NavDropdown.Item>
                                <NavDropdown.Item href="https://kyc.cryptogirl.finance/logout">
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                          </span>
                      </span>
                  </Nav>
              </Nav>;
      }

    return (
        <div>
            <div className="d-block d-lg-none logoTopMobile">
                <Link to="/">
                    <img id="logoMobile" src={LogoCryptoGirl} alt=""/>
                </Link>
                {
                    /*
                    <img title={this.props.defiName} id="logo" src="/logo.svg" className="d-inline-block align-top" alt="" />
                    &nbsp; {this.props.defiName}
                    */
                }
            </div>
            <Navbar id="menu" bg="light" expand="lg">
            <Navbar.Brand>
                <Link
                    title={this.props.defiName + '.finance'}
                    className="navbar-brand col-sm-3 col-md-2 mr-0"
                    to="/">
                        <img title={this.props.defiName} id="logo" src="/logo.svg" className="d-inline-block align-top" alt="" />
                        &nbsp; {this.props.defiName}
                        {/*
                        <span>.finance</span>
                        */}
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="menuLinks">
                    {/*
                    <Nav>
                        <Link to="/">Home</Link>
                    </Nav>


                    <Nav>
                        <Link to="/farm">Farms</Link>
                    </Nav>

                    */}

                    <Nav>
                        <Link to="/earn/farms">Earn</Link>
                    </Nav>

                    {/*
                    <NavDropdown title="Farm">
                        <NavDropdown.Item>
                            <Link to="/farm">Native</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/farm-non-native">Non Native</Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Pool">
                        <NavDropdown.Item>
                            <Link to="/pool">Native</Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <Link to="/pool-non-native">Non Native</Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                    */}

                    {/**/}

                    <Nav>
                        <Link to="/the-game">The Game</Link>
                    </Nav>

                    <Nav>
                        <a target="_blank" href={'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + this.props.ourTokenContract}>Exchange</a>
                    </Nav>
                    {/**/}

                    {/*
                    <NavDropdown title="Trade">
                        <NavDropdown.Item target="_blank" href={'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=' + this.props.ourXlionTokenContract}>
                            Buy XLION
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/change-tokens">
                            S2X
                        </NavDropdown.Item>
                    </NavDropdown>
                    */}
                    {/*
                    <Nav>
                        <Link to="/audit">Audit</Link>
                    </Nav>
                    */}

                    <Nav>
                        {/*<a target="_blank" href="/whitepaper-crypto-girl.pdf">Whitepaper</a>*/}
                        <Link to="/whitepaper">Whitepaper</Link>
                    </Nav>

                    {loginSignInBox}

                    {/*
                    <Nav>
                        <Link to="/referral">Referral</Link>
                    </Nav>
                    */}

                    {/*
                    <NavDropdown title="More" id="basic-nav-dropdown">
                        <NavDropdown.Item target="_blank" href="https://github.com/SuperLionFinance/">Github</NavDropdown.Item>
                        <NavDropdown.Item target="_blank" href="https://superlionfinance.gitbook.io/superlion-finance/">Docs</NavDropdown.Item>
                    </NavDropdown>
                    */}
                </Nav>
                <Nav className="menuRight">
                    <Nav id="tokenPrice">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}

                            <a href={'https://poocoin.app/tokens/' + this.props.ourTokenContract} target="_blank">
                                {/*<span className="tokenName">{this.props.ourTokenName}</span>&nbsp;*/}
                                <span className="tokenName">
                                        <img style={{width: '20px'}} src={require('../img/energycryptogirl.svg')} alt=""/>
                                    </span>&nbsp;
                                <span className="tokenPrice">{ourTokenPrice}</span>
                            </a>

                            <span className="separator"></span>

                            <a href={'https://poocoin.app/tokens/' + this.props.ourSecondTokenContract} target="_blank">
                                {/*<span className="tokenName">{this.props.ourSecondTokenName}</span>&nbsp;*/}
                                <span className="tokenName">
                                    <img style={{width: '20px'}} src={require('../img/cryptogirl.svg')} alt=""/>
                                </span>&nbsp;
                                <span className="tokenPrice">{ourSecondTokenPrice}</span>
                            </a>

                    </Nav>
                    <Nav id="account">
                        <div id="accountAddr">
                            {account}
                        </div>
                        {/*
                        <div id="loggoutContainer">
                            {logoutButton}
                        </div>
                        */}
                    </Nav>

                    <Nav className="footerMenuInsideMenu d-block d-lg-none">
                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">RoadMap</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Docs</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Telegram EN</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Telegram ES</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Twitter</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Telegram</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Audit</a>

                        <a target="_blank" href="https://cryptogirl.gitbook.io/superlion-finance/">Github</a>
                    </Nav>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        </div>

        /*

        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="/">Home</NavDropdown.Item>
            <NavDropdown.Item href="/farm">Farm</NavDropdown.Item>
            <NavDropdown.Item href="/pool">Pool</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item id="cuenta">
                {account} {logoutButton}
            </NavDropdown.Item>
        </NavDropdown>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a
                    className="navbar-brand col-sm-3 col-md-2 mr-0"
                    href="http://cristian.pro"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={farmer} width="30" height="30" className="d-inline-block align-top" alt="" />
                    &nbsp; {this.props.defiName}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item float-left col-3">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="nav-item float-left col-3">
                            <Link to="/farm">Farm</Link>
                        </li>
                        <li className="nav-item float-left col-3">
                            <Link to="/pool">Pool</Link>
                        </li>

                        <li id="cuenta" className="nav-item float-left col-3">
                            {account} {logoutButton}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow col-12">
        <a
          className="navbar-brand col-sm-3 col-md-2 mr-0"
          href="http://cristian.pro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={farmer} width="30" height="30" className="d-inline-block align-top" alt="" />
          &nbsp; {this.props.defiName}
        </a>

        <ul id="menu" className="col-8">

                <li className="nav-item float-left col-3">
                    <Link to="/">Home</Link>
                </li>
                <li className="nav-item float-left col-3">
                    <Link to="/farm">Farm</Link>
                </li>
                <li className="nav-item float-left col-3">
                    <Link to="/pool">Pool</Link>
                </li>

            <li id="cuenta" className="nav-item float-left col-3">
                {account} {logoutButton}
            </li>
        </ul>
      </nav>
      */
    );
  }
}

export default Menu;
