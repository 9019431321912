import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class RegisterPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="row rowReferral">
                <div className="col-12 loginPageContainer">
                    <iframe width="100%" height="1500" src="https://kyc.cryptogirl.finance/register" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterPage);
