import React, { Component } from 'react'
import {toast} from "react-toastify";
import {Link, withRouter} from "react-router-dom";

class LoginPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div className="row rowReferral">
                <div className="col-12 loginPageContainer">
                    <iframe id="loginIframe" width="100%" height="1500" src="https://kyc.cryptogirl.finance/user/dashboard" onLoad={(event => {
                        this.props.getAuthUserData();

                        let iFrameID = document.getElementById('loginIframe');
                        //alert(iFrameID);
                        //alert(iFrameID.contentWindow.document.body.scrollHeight);

                        if (iFrameID) {
                            // here you can make the height, I delete it first, then I make it again

                            /*
                            iFrameID.height = "";
                            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";

                            console.log('aaaaaaa');
                            console.log(iFrameID.contentWindow.document.body.scrollHeight);
                            */
                        }

                    })}
                    frameborder="0" scrolling="no"></iframe>
                </div>
            </div>
        );
    }
}

export default withRouter(LoginPage);
